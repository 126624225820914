const pingStatistics = state => state.pingStatistics
const notifications = state => state.notifications
const notificationsNumber = state => state.notificationsNumber
const drawer = state => state.drawer
const customerList = state => state.customers

export default {
  pingStatistics,
  notifications,
  notificationsNumber,
  drawer,
  customerList,
}
