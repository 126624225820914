import router from '../../../router'
import axiosAPI from '../../../../axios-API'
import handleResponse from '../../../services/responseHandler'

function login ({ commit, state }, { username, password, rememberUser }) {
  commit('LOGIN_REQUEST', { username })

  axiosAPI.post('/customers/auth/login', { username: username, password: password })
    .then(user => {
      if (user.data.result.token) {
        user.data.result.user.token = user.data.result.token
        if (rememberUser) {
          localStorage.setItem('rememberUser', JSON.stringify(user.data.result.user))
          delete user.data.result.user.username
          delete user.data.result.user.password
          localStorage.setItem('user', JSON.stringify(user.data.result.user))
        } else {
          delete user.data.result.user.username
          delete user.data.result.user.password
          localStorage.setItem('user', JSON.stringify(user.data.result.user))
          localStorage.removeItem('rememberUser')
        }
      }
      return user.data
    })
    .then(user => {
      commit('LOGIN_SUCCESS', user.result.user)
      router.push(state.destination || '/')
    },
    error => {
      commit('LOGIN_FAILURE', error)
      commit('SET_TIMEOUT')
      const errorType = (error.toString()).split('Error: Request failed with status code ')[1]
      if (errorType === '422') {
        commit('LOGIN_ERROR', 'Preencha corretamente os campos!')
      } else if (errorType === '500') {
        commit('LOGIN_ERROR', 'Senha e/ou usuário incorreto(s)')
      } else if (errorType.toNumber() > 500 && errorType.toNumber() < 600) {
        commit('LOGIN_ERROR', 'Erro de servidor')
      } else {
        commit('LOGIN_ERROR', 'Erro não registrado de código ' + errorType)
      }
    })
}

function snackbarError ({ commit }, val) {
  commit('ERROR_SNACKBAR', val)
}

function logout ({ commit }) {
  localStorage.removeItem('user')
  commit('LOGOUT')
}

function register ({ commit }, user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  commit('REGISTER_REQUEST', user)

  axiosAPI.post('/users/register', requestOptions).then(handleResponse)
    .then(
      user => {
        commit('REGISTER_SUCCESS', user)
        router.push('/login')
        setTimeout(() => {
          // display success message after route change completes
        })
      },
      error => {
        commit('REGISTER_FAILURE', error)
      },
    )
}

export default {
  login,
  logout,
  register,
  snackbarError,
}
