export default {
  customers: {},
  currentCustomer: {},
  ngrokGeneratedToken: '',
  pingStatistics: {
    onlineCustomers: [],
    offlineCustomers: [],
    updatedAt: '',
  },
  updating: false,
}
