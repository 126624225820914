
const fileList = state => { return state.files }
const getDialog = state => { return state.dialogState }
const getDirectories = state => { return state.directories }
const bucketName = state => { return state.bucket }

export default {
  fileList,
  getDialog,
  getDirectories,
  bucketName,
}
