import Vue from 'vue'
import Router from 'vue-router'
import jwt from 'jsonwebtoken'
import store from './store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login/Index'),
    },
    {
      path: '/monitor/:id',
      name: 'Monitoramento',
      component: () => import('@/views/monitor/Index'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Usuários',
          path: '/users',
          component: () => import('@/views/dashboard/listUsers'),
        },
        {
          name: 'Arquivos AWS',
          path: '/backup/files',
          component: () => import('@/views/dashboard/awsFiles'),
        },
        {
          name: 'Servidores AWS',
          path: '/aws-servers',
          component: () => import('@/views/dashboard/awsServers'),
        },
        {
          name: 'Clientes com serviço WEB',
          path: '/list-customer',
          component: () => import('@/views/dashboard/listCustomers'),
        },
        {
          name: 'Configuração de servidores',
          path: '/config-servers',
          component: () => import('@/views/dashboard/configServers'),
        },
        {
          name: 'Executaveis',
          path: '/exec',
          component: () => import('@/views/dashboard/Executables'),
        },
        {
          name: 'Perguntas Frequentes',
          path: '/faq',
          component: () => import('@/views/dashboard/FAQ'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/monitor/:id']
  const authRequired = !publicPages.includes(to.matched[0].path)
  const user = JSON.parse(localStorage.getItem('user'))
  let loggedIn = 0
  if (user) {
    const userToken = user.token
    await jwt.verify(userToken, process.env.VUE_APP_SECRET_JWT, (err, decoded) => {
      if (err) {
        console.error(err.message)
      }
      if (!decoded) {
        loggedIn = 0
      } else {
        loggedIn = 1
      }
    })
  }

  if (authRequired && !loggedIn) {
    console.error('Acess Forbidden!')
    store.commit('account/CHANGE_DESTINATION', to.fullPath)
    return next('/login')
  }
  next()
})

export default router
