<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>
<style>

a, h1, h2, h3, h4, h5, h6, span, label,
  .mouse-default{
    cursor: default;
  }

  .mouse-pointer {
    cursor: pointer;
  }

</style>
