
const SET_CUSTOMERS = (state, All) => {
  state.customers = All
}

const SET_CURRENT_CUSTOMER = (state, customer) => {
  state.currentCustomer = customer
}

const KEEP_GENERATED_NGROK_AUTH = (state, token) => {
  state.ngrokGeneratedToken = token
}

const SET_PING_STATISTICS = (state, obj) => {
  state.pingStatistics = obj
}

const SET_UPDATE_START = (state) => {
  state.updating = true
}

const SET_UPDATE_END = (state) => {
  state.updating = false
}

export default {
  SET_CUSTOMERS,
  SET_CURRENT_CUSTOMER,
  KEEP_GENERATED_NGROK_AUTH,
  SET_PING_STATISTICS,
  SET_UPDATE_START,
  SET_UPDATE_END,
}
