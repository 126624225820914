
function success ({ commit }, message) {
  commit('SUCCESS', message)
}

function error ({ commit }, message) {
  commit('ERROR', message)
}

function clear ({ commit }) {
  commit('CLEAR')
}

export default {
  success,
  error,
  clear,
}
