const LOGIN_REQUEST = (state, user) => {
  state.status = {
    loggingIn: true,
  }
  state.user = user
}

const LOGIN_SUCCESS = (state, user) => {
  state.status = {
    loggedIn: true,
  }
  state.user = user
}

const LOGIN_FAILURE = (state) => {
  state.status = {}
  state.user = null
}

const LOGIN_ERROR = (state, val) => {
  state.loginError = val
  state.hadError = true
}

const ERROR_SNACKBAR = (state, val) => {
  state.hadError = val
}

const LOGOUT = (state) => {
  state.status = {}
  state.user = null
}

const REGISTER_REQUEST = (state) => {
  state.status = {
    registering: true,
  }
}

const REGISTER_SUCCESS = (state) => {
  state.status = {}
}

const REGISTER_FAILURE = (state) => {
  state.status = {}
}

const CHANGE_DESTINATION = (state, value) => {
  state.destination = value
}

export default {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ERROR,
  ERROR_SNACKBAR,
  LOGOUT,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  CHANGE_DESTINATION,
}
