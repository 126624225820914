
import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import alert from './modules/alert'
import dashboard from './modules/dashboard'
import customers from './modules/customers'
import users from './modules/users'
import files from './modules/files'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    account,
    alert,
    dashboard,
    customers,
    users,
    files,
  },
})

export default store
