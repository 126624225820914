import store from '../store/index'

function handleResponse (entry) {
  console.log(entry)
  console.log(store)
  return entry
}

export default {
  handleResponse,
}
