/* eslint-disable no-undef */
export function authHeader () {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.token) {
    return {
      // 'Authorization': 'Bearer ' + user.token,
      Authorization: 'YWxleGFuZHJlOnJpc2MxMjY2',
      'x-access-token': user.token,
    }
  } else {
    return {}
  }
}
