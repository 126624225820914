const SET_FILES = (state, val) => {
  state.files = val
}
const SET_DIALOG = (state, val) => {
  state.dialogState = val
}
const SET_DIRECTORIES = (state, val) => {
  state.directories = val
}
const SET_BUCKET = (state, val) => {
  state.bucket = val
}

export default {
  SET_FILES,
  SET_DIALOG,
  SET_DIRECTORIES,
  SET_BUCKET,
}
