
const GET_ALL_REQUEST = (state) => {
  state.all = {
  loading: true,
  }
}

const GET_ALL_SUCCESS = (state, users) => {
  state.all = {
    items: users,
  }
}

const GET_ALL_FAILURE = (state, error) => {
  state.all = {
    error,
  }
}

const KEEP_FOUND_USER = (state, user) => {
  state.foundUser = user
  state.foundUserLoading = 0
}

export default {
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  KEEP_FOUND_USER,
}
