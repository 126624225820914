import axios from 'axios'

const domain = process.env.VUE_APP_URL_SYNC

const axiosSync = axios.create({
  domain,
  baseURL: process.env.VUE_APP_URL_SYNC,
})

axiosSync.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('user'))

  config.headers.Authorization = `Bearer ${user.token}`
  config.headers['x-access-token'] = user.token

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default axiosSync
