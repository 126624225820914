export default {
  user: {
    status: {},
    user: null,
  },
  destination: '',
  loginError: '',
  hadError: false,
  timeout: 2000,
}
