function generateDrawer ({ commit }, val) {
  commit('SET_DRAWER', val)
}

function addNotifications ({ commit, state }, array) {
  const arr = []
  state.notifications.map((entry) => {
    arr.push(entry)
  })
  array.map((entry) => {
    if (!state.notifications.includes(entry)) {
      arr.push(entry)
    }
  })
  commit('SET_NOTIFICATIONS', arr)
  commit('SET_NOTIFICATIONS_NUMBER', arr.length)
}

export default {
  generateDrawer,
  addNotifications,
}
