import { authHeader } from '../../../services/auth-header'
import axiosAPI from '../../../../axios-API'

async function getAll ({ commit }) {
  commit('GET_ALL_REQUEST')

  const config = {
    headers: authHeader(),
  }
  return await axiosAPI.get('/api/spitzer/sync/users', config).then(item => item.data.result)
    .then(
      users => commit('GET_ALL_SUCCESS', users),
      error => commit('GET_ALL_FAILURE', error),
    )
}

function changePassword ({ commit }, [newpassword, id]) {
  const user = JSON.parse(localStorage.getItem('user'))
  const head = {
    headers: {
      Authorization: 'NjZP0iMFshYpGcz5irS2k2u6XwEQCl',
      'x-access-token': user.token,
    },
  }
  const body = {
      password: newpassword,
  }
  axiosAPI.put(`/api/spitzer/sync/users/recovery-pass/${id}`, body, head)
}

async function findUserByName ({ commit }, searchUser) {
  const user = JSON.parse(localStorage.getItem('user'))
  const head = {
    headers: {
      Authorization: 'NjZP0iMFshYpGcz5irS2k2u6XwEQCl',
      'x-access-token': user.token,
    },
  }
  const body = {
      name: searchUser,
  }
 await axiosAPI.post('/api/spitzer/sync/users/search', body, head).then((res) => {
    commit('KEEP_FOUND_USER', res.data.result)
  })
}

async function findUserByUsername ({ commit }, searchUsername) {
  const user = JSON.parse(localStorage.getItem('user'))
  const head = {
    headers: {
      Authorization: 'NjZP0iMFshYpGcz5irS2k2u6XwEQCl',
      'x-access-token': user.token,
    },
  }
  const body = {
      username: searchUsername,
  }
  return await axiosAPI.post('/api/spitzer/sync/users/search', body, head).then((res) => {
    commit('KEEP_FOUND_USER', res.data.result)
  })
}

function deleteUser ({ commit }, id) {
  const user = JSON.parse(localStorage.getItem('user'))
  const head = {
    headers: {
      Authorization: 'NjZP0iMFshYpGcz5irS2k2u6XwEQCl',
      'x-access-token': user.token,
    },
  }
  const adress = `/api/spitzer/sync/users/${id}`
  return axiosAPI.delete(adress, head)
  // console.log(id, adress, head)
}

export default {
  getAll,
  deleteUser,
  changePassword,
  findUserByName,
  findUserByUsername,
}
