import axiosSYNC from '../../../../axios-SYNC'
import fileArray from '../../../services/fileArray'

async function listFiles ({ commit }, currentBucket) {
  const config = {
    params: {
      bucket: currentBucket,
      prefix: '',
    },
  }

  const response = await axiosSYNC.get('/list', config)

  const baseResponse = response.data.Contents.map(a => ({
    name: a.Key,
    size: a.Size,
    path: a.Key,
    icon: '',
  }))
  const arrayFinal = fileArray.arrayMagic(baseResponse, currentBucket, currentBucket)
  fileArray.sizeSum(arrayFinal)
  fileArray.iconPlacer(arrayFinal)
  commit('SET_FILES', arrayFinal)
}

async function listBuckets ({ commit }, currentBucket) {
  return await axiosSYNC.get('/list/buckets')
}

function updateDialog ({ commit }, value) {
  commit('SET_DIALOG', value)
}
function updateDirectories ({ commit }, value) {
  commit('SET_DIRECTORIES', value)
}

export default {
  listFiles,
  listBuckets,
  updateDialog,
  updateDirectories,
}
