function arrayMagic (files, currentBucket, currentPath) {
  const fileBase = [{ name: currentBucket, children: [], size: 0, icon: 'mdi-pail', path: currentPath }]
  const filesInFile = []
  const leftOvers = []
  let stringTest = ''

  files.forEach(file => {
    // checa se esta na pasta base ou na pasta fantasma
    if (file.name.match(/^(\/)/)) {
      // remove a barra da pasta fantasma
      file.name = file.name.replace('/', '')
      fileBase[0].children.push(file)
    } else if (!file.name.includes('/')) {
      fileBase[0].children.push(file)
    } else {
      // guarda os nomes das pastas dentro de stringTest e joga os arquivos que sobraram pro left overs
      stringTest = file.name.substr(0, file.name.indexOf('/'))
      leftOvers.push(file)
      if (!filesInFile.includes(stringTest)) { filesInFile.push(stringTest) }
    }
  })

  // cria as subpastas
  filesInFile.forEach(file => {
    fileBase[0].children.push({
      name: file,
      children: [],
      size: 0,
      icon: '',
      path: currentPath + '/' + file,
    })
  })

  leftOvers.forEach(leftOversItem => {
    // primeiro roda todos os arquivos que sobraram
    filesInFile.forEach(file => {
      // depois verifica o nome de cada pasta e se o nome do arquivo inclui o nome da pasta
      // (ex: backup/alguma_coisa inclui backup)
      if (leftOversItem.name.split('/')[0] === file) {
        // se incluir ele busca no array base qual é o objeto com o nome da pasta e inclui no children dele o arquivo
        fileBase[0].children.map((val) => {
          if (file === (val.name)) {
            // retirando o nome da pasta do nome do arquivo
            leftOversItem.name = leftOversItem.name.replace(file + '/', '')
            // se o nome do arquivo for uma string vazia ele não aparecerá
            if (leftOversItem.name !== '') {
              val.children.push(leftOversItem)
            }
          }
        })
      }
    })
  })

  fileBase[0].children.forEach(file => {
    if (file.children) {
      // testa se o componente tem children e se tiver faz uma recursão na função em cima do children dele
      const childrenChildren = arrayMagic(file.children, file.name, file.path)
      file.children = childrenChildren[0].children
    }
  })

  return fileBase
}

function sizeSum (files) {
  let sum = 0
  let outerSum = 0
  files.forEach(file => {
    if (file.children) {
      const tamanho = this.sizeSum(file.children)
      Object.assign(file, { size: tamanho })
      outerSum += tamanho
    } else {
      sum += file.size
    }
  })
  return sum + outerSum
}

function iconPlacer (files) {
  files.forEach(file => {
    if (file.children) {
      iconPlacer(file.children)
    } else {
      if (file.name.endsWith('.pdf')) Object.assign(file, { icon: 'mdi-file-pdf-box' })
      else if (file.name.endsWith('.jpg')) Object.assign(file, { icon: 'mdi-file-image-outline' })
      else if (file.name.endsWith('.zip')) Object.assign(file, { icon: 'mdi-folder-zip-outline' })
      else if (file.name.endsWith('.exe')) Object.assign(file, { icon: 'mdi-file-code-outline' })
      else if (file.name.endsWith('.rar')) Object.assign(file, { icon: 'mdi-folder-zip-outline' })
      else Object.assign(file, { icon: 'mdi-file-document-outline' })
    }
  })
}

export default {
  arrayMagic,
  sizeSum,
  iconPlacer,
}
