import axiosAPI from '../../../../axios-API'
import authToken from '../../../../axios-NGROK'
import moment from 'moment'

async function getCustomers ({ commit }) {
  commit('SET_UPDATE_START')
  const user = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: 'YqiCC2TYmgD0uJ0CmGB2LZ2ZVyynYoaW06Ad',
      'x-access-token': user.token,
    },
  }
  return axiosAPI.get('/customers/verif-status', config)
    .then((res) => {
      const offlineCustomers = res.data.result.off
      const onlineCustomers = res.data.result.on
      commit('SET_PING_STATISTICS', { onlineCustomers: onlineCustomers, offlineCustomers: offlineCustomers, updatedAt: moment().format('LLL') })
      commit('SET_UPDATE_END')
      return res.data
    })
    .catch((err) => {
      console.error('Could not get customer list.\n', err.message)
      commit('SET_UPDATE_END')
    })
}

async function getCustomer ({ commit }, customerId) {
  const user = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: 'YqiCC2TYmgD0uJ0CmGB2LZ2ZVyynYoaW06Ad',
      'x-access-token': user.token,
    },
  }
  return await axiosAPI.get(`/customers/${customerId}`, config).then((res) => res.data.result)
}

async function listCustomers ({ commit }) {
  const user = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: 'YqiCC2TYmgD0uJ0CmGB2LZ2ZVyynYoaW06Ad',
      'x-access-token': user.token,
    },
  }
  return await axiosAPI.get('/customers', config).then((res) => {
    commit('SET_CUSTOMERS', res.data.result.map(data => ({
      ...data,
      version: data.enabled ? data.tentacle.version || 'Não identificada' : 'Desabilitado',
      runMode: data.enabled ? data.tentacle.runMode || 'Não identificado' : 'Desabilitado',
    })))
    return res.status
  })
}

async function sendCustomerForm ({ commit }, data) {
  const user = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: 'YqiCC2TYmgD0uJ0CmGB2LZ2ZVyynYoaW06Ad',
      'x-access-token': user.token,
    },
  }

  // If data has _id, edit customer
  if (data._id) {
    return await axiosAPI.put(`/customers/${data._id}`, data, config).then((res) => res.status)
  }
  // else create new
  return await axiosAPI.post('/customers', data, config)
}

async function getTentacleConfigCredential ({ commit }, customerId) {
  const user = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      'x-access-token': user.token,
    },
  }

  // If data has _id, edit customer
  if (customerId) {
    return await axiosAPI.post(`/get-config/${customerId}`, {}, config).then((res) => res.data.token)
      .catch((err) => ({ err: err.error }))
  } else {
    return { err: 'Obrigatório informar id do cliente.' }
  }
}

async function generateAuthNgrok ({ commit }, { user, name }) {
  const data = {
    description: `Dashboard; Created by: ${user}, Customer:${name.toUpperCase()}`,
  }
  return await authToken.post('/credentials', data).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return { token: res.data.token, uri: res.data.uri, err: '' }
    } else {
      return { token: '', uri: '', err: res.msg }
    }
  }).catch((err) => {
    return { token: '', uri: '', err: err.response.data.msg }
  })
}

async function generateReservedDomain ({ commit }, { user, name, subdomain }) {
  const data = {
    description: `Dashboard; Created by: ${user}, Customer:${name.toUpperCase()}`,
    name: subdomain,
  }
  return await authToken.post('/reserved_domains', data)
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return { domain: res.data.domain, err: '' }
      } else {
        return { domain: '', err: res.data.msg }
      }
    })
    .catch((err) => {
      return { domain: '', err: err.response.data.msg }
    })
}

export default {
  getCustomer,
  getCustomers,
  listCustomers,
  sendCustomerForm,
  generateAuthNgrok,
  generateReservedDomain,
  getTentacleConfigCredential,
}
