import axios from 'axios'

const domain = process.env.VUE_APP_URL_API

const axiosInstance = axios.create({
  domain,
  baseURL: process.env.VUE_APP_URL_API,
})

axiosInstance.interceptors.request.use(function (config) {
  const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')
  if (localStorageUser && localStorageUser.token) {
    config.headers['x-access-token'] = localStorageUser.token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

export default axiosInstance
