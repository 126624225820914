import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: {
        primary: '#38c1ff', // '#03A9F4',
        secondary: '#00a6ff', // '#00838F',
        accent: '#9C27b0',
        info: '#00CAE3',
      },
      light: {
        primary: '#03A9F4',
        secondary: '#1797ff',
        accent: '#9C27b0',
        info: '#3F51B5',
      },
    },
    dark: JSON.parse(localStorage.getItem('isDarkMode')),
  },
})
