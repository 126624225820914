const SET_BAR_IMAGE = (state, payload) => {
  state.barImage = payload
}

const SET_DRAWER = (state, payload) => {
  state.drawer = payload
}

const SET_NOTIFICATIONS = (state, payload) => {
  state.notifications = payload
}
const SET_NOTIFICATIONS_NUMBER = (state, payload) => {
  state.notificationsNumber = payload
}

export default {
  SET_DRAWER,
  SET_BAR_IMAGE,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_NUMBER,
}
