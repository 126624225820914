import axios from 'axios'

// eslint-disable-next-line camelcase
const auth_token = process.env.VUE_APP_AUTH_NGROK

export default axios.create({
  baseURL: 'https://api.ngrok.com',
  headers: {
    Authorization: auth_token,
    'X-Ngrok-Version': '1',
    'Content-Type': 'application/json',
  },
})
