
const SUCCESS = (state, message) => {
  state.type = 'alert-success'
  state.message = message
}

const ERROR = (state, message) => {
  state.type = 'alert-danger'
  state.message = message
}

const CLEAR = (state) => {
  state.type = null
  state.message = null
}

export default {
  SUCCESS,
  ERROR,
  CLEAR,
}
